@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply inline-block px-6 py-2 font-semibold text-center text-white rounded-sm transition duration-150 ease-in-out;
  }

  .btn-tall {
    @apply py-3;
  }

  .title {
    @apply mb-4 text-3xl font-extrabold text-center text-white;
  }

  .title-small {
    @apply text-2xl font-bold;
  }

  .intro {
    @apply text-xl text-gray-500 text-center mx-8 mb-12 max-w-sm;
  }

  .text {
    @apply text-xl text-gray-500;
  }
}

@layer utilities {

}
